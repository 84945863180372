.nft-minting-card-container {
  background: linear-gradient(to bottom, #6da76771, #a8dfa771, #ffffff17);
  padding: 20px;
}
.nft-img {
  width: 300px;
}
.nft-name {
  text-align: center;
  color: var(--bs-text-color);
}
.d-flex {
  display: flex;
}
.f-center {
  justify-content: center;
}
.nft-mint-btn {
  background-color: var(--bs-main-color) !important;
  border: none !important;
  font-size: 20px;
}
.opensea-btn {
  background-color: var(--bs-main-color) !important;
  border: none !important;
  font-size: 20px;
  text-align: center;
}
.nft-mint-minus {
  background-color: var(--bs-main-color) !important;
  border: none !important;
}
.nft-mint-amount {
  background-color: transparent !important;
  border: none !important;
  color: var(--bs-text-color) !important;
}
.nft-mint-plus {
  background-color: var(--bs-main-color) !important;
  border: none !important;
}
.nft-mint-number {
  font-size: 36px;
  text-align: center;
  font-weight: bold;
  color: var(--bs-text-color);
}
.nft-mint-price {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  color: var(--bs-text-color);
  margin-top: 1rem;
}
.video-skip-btn {
  color: white !important;
  text-decoration: none;
  font-size: 24px;
}
.video-skip-section {
  position: absolute;
  left: 40px;
  bottom: 50%;
}
.videoIntro {
  overflow: hidden !important;
  position: absolute;
  top: 0px;
  left: 0px;
  background: black;
}
.nft-image {
  width: 100%;
  border-radius: 50%;
}
.nft-page-title {
  background: linear-gradient(to bottom, #6da76771, #a8dfa771, #ffffff17);
  width: 220px;
  text-align: center;
  border-radius: 10px;
}
.nft-minting-card {
  background: #ade6b9;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
}
.t-center {
  text-align: center;
  color: black;
}
.mint-btn-field {
  padding-top: 20px;
  padding-bottom: 20px;
}
.nft-image-field {
  margin-top: auto !important;
  margin-bottom: auto;
}
.main-field {
  padding-top: 20px;
  padding-bottom: 20px;
}
.desc-field {
  padding-top: 30px;
  padding-bottom: 30px;
}
.title-field {
  margin-top: 12px !important;
}
.desc-content {
  color: var(--bs-text-color);
}
.nft-contract-address {
  text-align: center;
  cursor: pointer;
  color: var(--bs-text-color);
}
